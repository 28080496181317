/* @import url("https://fonts.googleapis.com/css2?famoly=Roboto:Wght@300;400;500;700;900&disploy=swop");

*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}
body {
    font-family: "Roboto" sans-serif ;
   
  
   
}
.header {
  text-align: center;
  padding: 20px;
}

  
.container {
    max-width: 85%;
    margin: auto;
    padding: 20px;
    width: 100%;
    
}
.flexSB {
    display: flex;
    justify-content: space-between;

}

.card {
  width: 30%;
  padding: 20px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgb(0, 0, 0, 0.1);
}


.row {
    width: 50%;
}



.image-box:nth-child(1) {
    animation-delay: 0s;
  }
  
  .image-box:nth-child(2) {
    animation-delay: 1s;
  }
  
  .image-box:nth-child(3) {
    animation-delay: 2s;
  }
  
 
  .image-box {
    animation: slideInOut 2s ease-in-out infinite;
  }
  
  @keyframes slideInOut {
    0%, 100% {
      transform: translateX(0);
    }
    50% {
      transform: translateX(10px);
    }
  }

@media only screen and (max-width: 768px) {
  .container {
    width: 100%;
    padding: 10px;
  }

  .content {
    flex-direction: column;
    align-items: center;
  }

  .card {
    width: 100%;
    margin-bottom: 20px;
  }

  .header {
    font-size: 1.5rem;
    padding: 10px;
  }
  
}

@media  screen  (max-width: 480px) {
  .header {
    font-size: 1.2rem;
  }

  .card {
    padding: 10px;
  }
} */


@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  font-family: "Roboto", sans-serif;
  background-color: white;
  
}

.header {
  text-align: center;
  padding: 20px;
}

/* Container with responsive padding */
.container {
  width: 85%;
  margin: auto;
  ;
}

/* Flex layout for space between items */
.flexSB {
  display: flex;
  justify-content: space-between;
  /* flex-wrap: wrap;  */
}

/* Card styling with responsiveness */
.card {
  width: 30%;
  padding: 20px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgb(0, 0, 0, 0.1);
  margin-bottom: 20px; /* Adds spacing between cards */
}

a {
  text-decoration: none;
}
li {
  list-style-type: none;
}


/* Row with width adjustment */
.row {
  width: 50%;

}
.hero .row {
  width: 100%;
}
/* .margin {
  margin-top: 40.3%;
} */

#heading {
  text-align: center;
  padding: 40px 0;
}
#heading h3 {
  font-weight: 600;
  letter-spacing: 1px;
  color: #1eb2a6;
  text-transform: uppercase;
}
#heading h1 {
  font-size: 45px;
  margin: 20px 0;
  text-transform: capitalize;
}
p {
  line-height: 30px;
  font-size: 18px;
}
h2{
  text: small;
  
}

/* Image animation delay */
/* .image-box:nth-child(1) {
  animation-delay: 0s;
}

.image-box:nth-child(2) {
  animation-delay: 1s;
}

.image-box:nth-child(3) {
  animation-delay: 2s;
}

.image-box {
  animation: slideInOut 2s ease-in-out infinite;
} */

@keyframes slideInOut {
  0%, 100% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(10px);
  }
}

