@tailwind base;
@tailwind components;
@tailwind utilities;


/* @layer base {
  button {
    @apply border py-2 px-3 rounded-full
  }
} */
/* :root {
  font-family: Inter, system-ui, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */

 /* body {
  margin: 0;
  min-width: 320px;
  min-height: 100vh;
  background: linear-gradient(145deg,
  #0a234f 11%, 
    #EDF2F7 10%, 
    #102a56 20%,
    #061735 1%
  );
  min-height: 100vh;
  color: #ac3535;
}  */

/* button {
  all: unset;
  cursor: pointer;
} */


